@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");
:root {
  --theme-color: rgb(254, 178, 15);
  --secondary-color: #e87c1e;
  --third-color: #ff2721;
  --primary-font-color: rgb(29, 29, 29);
  --primary-theme: rgb(241, 196, 15, 0.8);
  --primary-theme-second: rgb(46, 134, 222, 0.8);
  --secondary-black: #2c3e50;
  --font-color: black;
}

::selection {
  background: var(--theme-color);
  color: black;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  width: 100%;
  scroll-behavior: smooth;

  font-family: Poppins;
  overflow-x: hidden;
  transition: 0.3s ease-in-out;
}
a {
  color: white;
  text-decoration: none;
}
img {
  width: 100%;
  height: 100%;
}
/* 
header {
  height: 100vh;
  background: var(--theme-color);
  background: -webkit-linear-gradient(to right, --theme-color, #eacda3);
  background: linear-gradient(to right, --theme-color);

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Sen", sans-serif, "Tinos", serif;
}
.header {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 0%, 50%;
  position: relative;
}
.nav-display {
  display: none !important;
}
.nav-open {
  position: fixed;
  width: 65%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: var(--theme-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.nav-open ul li {
  list-style: none;
  font-size: 35px;
  padding: 30px 50px;
  color: var(--primary-font-color);
}
.nav-open ul li:hover {
  color: white;
}
.fixed-nav {
  font-size: 30px;
  color: var(--theme-color);
  width: 80px;
  height: 80px;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.bar {
  position: absolute;
  font-size: 2rem;
  cursor: pointer;
}
.bar div {
  height: 3px;
  width: 25px;
  border-radius: 20px;
  margin: 5px 0px;
  background-color: var(--primary-font-color);
  position: relative;
  transition: 0.2s ease-in-out;
}

.bar div:nth-child(2) {
  width: 20px;
}
.bar-active div {
  border-radius: 5px;
  color: #313131;
}
.bar-active div:nth-child(1) {
  transform: rotateZ(30deg);
  transform: translate(0, 8px) rotateZ(45deg);
}
.bar-active div:nth-child(2) {
  transform: translate(-30px) scale(0);
}
.bar-active div:nth-child(3) {
  transform: rotateZ(-45deg);
  transform: translate(0, -8px) rotateZ(-45deg);
}

nav {
  display: flex;
  color: #2c3e50;
  width: 90%;
  margin: 0 auto;
  height: 80px;
  align-items: center;
  position: relative;
}
nav > .logo {
  height: 50px;
  width: 50px !important;
  color: black;
  background-image: url(../images/logo.png);
  background-size: 50px 50px;
  background-repeat: none;
}

@media (max-width: 1000px) {
  .nav-open ul li {
    font-size: 25px;
  }
}
@media (max-width: 700px) {
  .nav-open ul li {
    font-size: 22px;
  }
  .nav-open {
    width: 65%;
  }
}

header .content {
  display: flex;
  width: 90%;
  margin: 0 auto;
  height: 80%;
  color: white;
  line-height: 130px;
  z-index: 10;
}
.content .left {
  width: 100%;
  align-self: center;
}
.content .left h1 {
  color: var(--theme-color);
  font-size: 5.5rem;
}
.content .left h2 {
  font-size: 2.5rem;
  color: var(--font-color);
  line-height: 30px;
} */

.social-icons {
  position: fixed;
  left: -135px;
  bottom: 250px;
  color: white;
  display: inline-flex;
  transform: rotate(-90deg);
  transition: 0.5s ease-in-out;
}
.social-icons div {
}
.social-icons a {
  text-decoration: none;
  padding: 10px 20px;
  color: #121212;
  position: relative;
  transition: 0.25s ease-out;
}
.social-icons .facebook a:hover {
  background-color: #3b5998;
  color: var(--font-color);
}
.social-icons .twitter a:hover {
  background-color: #00acee;
  color: var(--font-color);
}
.social-icons .linkedin a:hover {
  background-color: #0e76a8;
  color: var(--font-color);
}
.social-icons a::after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
}

@media (max-width: 700px) {
  .social-icons {
    visibility: hidden;
  }
}
@media (max-width: 800px) {
  .content .left h1 {
    font-size: 4.5rem;
  }
  .content .left h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 600px) {
  .content .left h1 {
    font-size: 3rem;
  }
}
@media (max-width: 450px) {
  .content .left h1 {
    font-size: 2.3rem;
  }
}

hr {
  margin: 50px 30px;
}

/* mission */
.mission {
  margin-top: 30px;
  color: white;
  height: 350px;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  color: var(--secondary-black);
  padding: 50px calc(10vw);
}
.mission .mission-container {
  height: 100%;
}
.mission .mission-container h1 {
  text-align: center;
  font-size: 4rem;
}
.mission .mission-container p {
  color: var(--primary-font-color);
  padding: 50px 10px;
  font-size: 1.5rem;
}

@media (max-width: 864px) {
  .mission {
    padding: 50px 10px;
  }
  .mission .mission-container h1 {
    font-size: 3rem;
  }
  .mission .mission-container p {
    padding: 30px 10px;
    font-size: 1.4rem;
  }
}

/* Future tab  Future tab Future tab Future tab
--------------------------------------------------- */
.showcase {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}
/* .showcase-img-1 {
  background-image: url("../images/showcase_1.jpg");
  background-size: cover;
}
.showcase-img-2 {
  background-image: url("../images/showcase_2.jpg");
  background-size: cover;
} */
.showcase-img {
  width: 100%;
  min-height: 200px;
  transition: 4s ease-in-out;
  background-size: 120%;
}
.showcase-img:hover {
  background-size: 180%;
}
.showcase-two {
  background-color: rgb(253, 255, 240);
  color: rgb(61, 60, 60);
  max-width: 500px;
  margin: 0px 20px;
}
.showcase-content {
  padding: 30px;
}
.showcase h3 {
  color: rgb(88, 87, 87);
  margin-top: 20px;
}

@media (max-width: 600px) {
  .showcase {
    flex-direction: column;
  }
}

.container-grid {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 10px;
  column-gap: 10px;
  padding: 30px 0px;
  grid-auto-rows: auto;
}
.poster {
  height: 400px;
  background-image: url("../images/img5.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  display: flex;
  justify-content: center;
  color: white;
  font-size: 20px;
  align-items: center;
  transition: 0.35s ease-in-out;
}
.poster:hover {
  transform: scale(1.1, 1.1);
  box-shadow: 1px 1px 0px 10px rgba(0, 0, 0, 0.8);
}
.poster:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.poster:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

/* Methodology Methodology Methodology Methodology Methodology */
/* .method {
  padding: 100px 0px;
  background-repeat: no-repeat;
  background-size: 80%;

  color: rgb(51, 51, 51);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 80px;
  font-family: Poppins;
}
.method-container {
  width: 70%;
  margin: 0 auto;
}
.method flex {
  display: flex;
}
.method .flex-left {
  flex: 1;
}
.method .flex-right {
  flex: 1;
}
.method-container h3 {
  line-height: 40px;
  color: rgb(88, 87, 87);
}
.method-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 80px;
  line-height: 80px;
}
.method-wrapper div {
  width: 200px;
}

@media (max-width: 900px) {
  .method .flex {
    flex-direction: column;
  }
}
@media (max-width: 700px) {
  .method-wrapper {
    justify-content: center;
  }
  .method .flex {
    flex-direction: column;
  }

  .method-container {
    width: 90%;
  }

  .method-wrapper div {
    width: 300px;
  }
} */

/* quotes quotes quotes quotes quotes
-------------------------------------  */
.quote {
  width: 100%;
  color: #212121;
  padding: 50px 0px;
}
.quote-container {
  width: 80%;
  margin: 0 auto;
}
.quote {
  font-size: 28px;
}
.quote span {
}
.author {
  font-size: 18px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  color: #121212;
  margin-left: 50px;
}
.author::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 5px;
  left: -50px;
  width: 40px;
  height: 2px;
  background-color: #212121;
}
@media (max-width: 700px) {
  .quote {
    font-size: 22px;
  }
  .author {
    font-size: 14px;
  }
}

/* extras extras extras extras extras */
.button {
  font-size: 1.5em;
  width: 28%;
  padding: 10px 15px;
  margin: 10px 0px;
  border: none;
  background-color: #e74c3c;
  color: white;
}
.space {
  height: 1000px;
}
.md-space {
  height: 100px;
}
.lg-space {
  height: 150px;
}
.mr-t-50 {
  margin-top: 200px;
}
.text-center {
  text-align: center;
}
.flex {
  display: flex;
}
.heading {
  color: var(--theme-color);
  text-transform: uppercase;
}
ul {
  list-style: none;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 200px;
  background-color: #121212;
  color: #f5f5f5;
  font-size: 22px;
  transform: translate(-50%, -50%);
}

.text-black {
  color: #212121 !important;
}
.text-green {
  color: rgb(41, 230, 20) !important;
}

/* slider -------------------------------------------------------------------- */
.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}

.image-slider {
  position: absolute;
  max-width: 100vw;
  max-height: 400px;
}

.example-container {
  width: 100vw;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
