.faq {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  min-height: 500px;

  .main-header {
    margin: 30px 0;
    text-align: center;
  }
  input {
    width: 100%;
    padding: 20px;
    font-size: 20px;
    outline: none;
    border: none;
    border-bottom: 1px solid #121212;
  }
}

.faq-card {
  margin: 30px 0;
  padding: 30px;
  background-color: rgba(33, 33, 240, 0.05);

  section {
    margin: 10px;
    h3 {
      margin: 10px 0;
    }
    ul {
      margin: 20px;
      li {
        margin: 10px;
      }
    }
    span {
      font-weight: bold;
    }
  }
}
